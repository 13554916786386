<template>
    <section style="height: 95%;">
        <div class="flex align-center" style="padding: 10px 0;">
            <img :src="bs"/>
            <span style="color:#FFFFFF;font-size: 18px;font-weight: bold;margin-left: 10px">项目占比</span>
        </div>
        <div style="height: 100%;border: 1px #2BA3FF dashed;padding: 10px;">      
            <div style="height: 50%;border-bottom: 1px #0A1367 solid;margin: 0 20px;font-size: 10px" class="slider">
                <div style="color: #fff; padding-left:15px ;  font-size: 16px; display: flex;margin-bottom: 10px;">
                    <div style="width: 40%;margin-left: -18px; "> <span style="background-color: rgb(21, 41, 132);padding: 5px ;"> 总房源:{{ total.totalNumber }}</span></div>
                    <div style="width: 40%; margin-left: 10px; "> <span style="background-color: rgb(21, 41, 132);padding: 5px ;">已出租: {{ total.number }}</span></div>
                    <div style="width: 45%; margin-left: 8px; "> <span style="background-color: rgb(21, 41, 132);padding: 5px ;"> 出租率:{{ total.rate }}%</span></div>
                </div>
                <!-- 新增标题 -->
                <div style="color:#fff;font-size: 12px; margin: 0 auto; display: flex; ">
                    <div style="width: 38%; padding-left: 28px;">房源类型</div>
                    <div style="width: 60%;">已出租/总房数</div>
                    <div style="width: 35%;">出租率(%)</div>
                </div>
                <div class="slider-item flex align-center justify-between" v-for="(item,index) in soilSettles"
                     :key="item.name" style="height:14%;">
                    <div class="flex align-center">
                        <div class="label"
                             style="background-color: #FF3232;padding: 1px 8px;font-size: 10px;border-radius: 3px;color: #FFFFFF">
                            {{ index + 1 }}
                        </div>
                        <div
                            style="border-left: 6px solid #FF3232;border-top: 4px solid transparent;border-bottom: 4px solid transparent;">
                        </div>
                        <div class="value" style="padding-left: 0;color: #FFFFFF;width: 80px;text-align-last: justify;">
                            {{ item.name }}
                        </div>
                    </div>
                    <div style="color: #FFFFFF;width: 50px;text-align: center">{{ item.value }}/{{ item.total }}</div>
                    <div style="color: #28C3F0;width: 50px;text-align: right">{{ item.rate }}%</div>
                    <el-slider style="width:100px" class="a1" :value="item.rate" disabled/>
                </div>
            </div>
            <div class="flex justify-between" style="height: 55%;margin-top: 10px;">
                <div id="projectECharts" style="height: 90%;width: 55%;"></div>
                <div style="height: 50%;width: 39%;">
                    <div style="font-size: 14px;font-weight: normal;color: #FFFFFF;">
                        <div v-for="(item,index) in soilSettles" :key="index" class="flex justify-between align-center"
                             style="margin: 10px 0;">
                            <div
                                :style="'background: linear-gradient(180deg, ' + item.beginBackground + ' 0%, ' + item.finishBackground + ' 100%)'"
                                style="width: 10px;height: 10px;border-radius: 5px;"></div>
                            <div class="flex" style="width: 90%;">
                                <div style="width: 85px;text-align-last: justify;">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import * as echarts from "echarts";
import {getApartmentTypeRentCondition} from "@/api/data-screen";
import {getRentTotal} from '@/api/data-screen';


const bs = require("@images/icon/bs.png");
export default {
    name: "project-proportion",
    data() {
        return {
            bs,
            ECharts: null,
            soilSettles: [
                {   
                    name: "富航公寓",
                    beginBackground: '#026DB2',
                    finishBackground: '#12FEE0',
                },
                {
                    name: "回购经适房",
                    beginBackground: '#6DD400',
                    finishBackground: '#44D7B6',
                },
                {
                    name: "拆迁安置房",
                    beginBackground: '#5C64FF',
                    finishBackground: '#6988F8',
                },
                {
                    name: "竞配产权房",
                    beginBackground: '#FFA600',
                    finishBackground: '#FEDB65',
                },
                {
                    name: "高级人才房",
                    beginBackground: '#EF9A50',
                    finishBackground: '#F31900',
                },
                {
                    name: "五象四号点",
                    beginBackground: '#026DB2',
                    finishBackground: '#12FEE0',
                },
            ],
            options: {
                tooltip: {
                    trigger: "item",
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['30%', '80%'],
                        label: {
                            normal: {
                                show: false,
                            }
                        },
                        tooltip: {
                            valueFormatter(value) {
                                return value + ' %';
                            }
                        },
                    }
                ]
            },
            // 新增头部
            total: {
                number: 0,
                rate: 0,
                totalNumber: 0,
            }
        }
    },
    components: {},
    methods: {
        eChartsInit(eChartsName, eChartsOptions) {
            let {...options} = eChartsOptions;
            this[eChartsName] = echarts.init(document.getElementById(eChartsName));
            this[eChartsName].setOption(options, true);
            window.addEventListener("resize", () => {
                this[eChartsName].resize();
            });
        },

        formatOption(eChartsOptions, data) {
            data = data.map(v => {
                const {name, totalRate: value} = v;
                return {name, value}
            });
            const {soilSettles} = this;
            const {series} = eChartsOptions;
            for (let item of data) {
                soilSettles.map(v => {
                    if (item.name === v.name) {
                        item.itemStyle = {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {offset: 0, color: v.beginBackground},
                                {offset: 1, color: v.finishBackground}
                            ])
                        }
                    }
                });
            }
            series[0].data = data;
            eChartsOptions.series = series;
            return eChartsOptions;
        },

        getApartmentTypeRentCondition() {
            const that = this;
            const {options, soilSettles} = that;
            getApartmentTypeRentCondition().then(res => {
                const {list} = res;
                const eChartsOptions = that.formatOption(options, list);
                that.soilSettles = list.map((v, i) => {
                    return {...soilSettles[i], ...v}
                });
                that.eChartsInit("projectECharts", eChartsOptions);
            })
        },
        // 新增头部
        getRentTotal() {
            const that = this;
            getRentTotal().then(res => {
                const {info} = res;
                that.total = {...info}
            });
        }
    },
    mounted() {
        this.getApartmentTypeRentCondition();
        // 新增头部
        this.getRentTotal();
    }
}
</script>

<style lang="scss" scoped>
.slider {
    .slider-item {
        padding-bottom: VH(8px);

        .label {
            //width: VW(90px);
            color: #33558B;
            font-weight: 600;
            //font-size: rem(18px);
        }

        .value {
            padding: 0 VW(10px);
            word-break: break-all
        }

        .rate {
            width: VW(50px);
        }

        /deep/ .el-slider {
            width: VW(150px);

            .el-slider__runway, .el-slider__bar {
                height: VH(13px);
                border-radius: 9px;
                background-color: #05689C;
                margin: 6px 0;
            }

            .el-slider__button-wrapper {
                display: none;
            }

            &.a1 .el-slider__bar {
                background: linear-gradient(90deg, rgba(19, 37, 67, 0.08) 0%, #00AEFF 100%);
                position: static;
            }
        }
    }
}
</style>